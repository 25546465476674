<template>
  <div class="pa-4">
    <div class="text-center">
      <img
        :src="require('@/assets/images/dashboard/icons/success.svg')"
        alt=""
      />
      <span class="d-block font-20 darkbord--text font-700"
        >Interview time has been set successfully</span
      >
    </div>
    <v-btn
      color="register-primary"
      dark
      elevation="0"
      class=" radius-5 mt-4"
      height="50px"
      block
      @click="$emit('done')"
      ><span class="font-700 font-16"> Done</span></v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    message: "",
    valid: false,
  }),
  methods: {},
};
</script>

<style></style>
