<template>
    <div class="interviews">
        <header-table
                v-if="!isMobile"
                :title="$t('dashboard.allInterviews')"
                @updateDate="items = $event.interviews"
                :url="userType == 'doctor' ? 'search' : 'interview/search'"
        ></header-table>

        <header-table-mobile
                v-if="isMobile"
                title="All Interviews"
                :isSort="true"
        ></header-table-mobile>
        <v-row v-if="loadingData">
            <v-col md="12">
                <v-skeleton-loader v-bind="attrs" type="article"></v-skeleton-loader>
            </v-col>
            <v-col md="12">
                <v-skeleton-loader v-bind="attrs" type="article"></v-skeleton-loader>
            </v-col>
            <v-col md="12">
                <v-skeleton-loader v-bind="attrs" type="article"></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-data-table
                v-if="!isMobile"
                :headers="headers"
                :items="items"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                @page-count="pageCount = $event"
                :key="itemsKeyUpdate"
        >
            <!-- name & image -->
            <template v-slot:item.hospital_name="{ item }">
                <div class="d-flex align-center">
                    <img
                            width="32px"
                            height="32px"
                            class="mx-3 circle"
                            :src="require('@/assets/images/logo-mini.png')"
                    />
                    <span class="font-16 greytext--text" v-if="item.hospital">{{
                        item.hospital.name
                        }}</span>
                    <span class="font-16 greytext--text" v-else>{{
                        item.doctor_name
                        }}</span>
                </div>
            </template>

            <!-- status -->
            <template v-slot:item.status="{ item }">
                <div
                        class="px-4 py-2 radius-10 d-inline-block pr-10"
                        :class="getStatusColorBg(item.status)"
                >
          <span
                  class="font-16 font-700"
                  :class="getStatusColorText(item.status)"
          >{{ item.status }}</span
          >
                </div>
            </template>
            <!-- time -->
            <template v-slot:item.interview_at="{ item }">
        <span
                class="font-16 greytext--text"
                v-if="item.book_time && userType == 'doctor'"
        >{{ item.interview_at }}</span
        >
                <span
                        class="font-16 greytext--text"
                        v-if="item.interview_time && userType != 'doctor' && item.book_time"
                >{{ item.interview_at }}</span
                >
                <div class="text-center" v-if="!item.book_time && !item.interview_time">
                    <span class="font-16 register-primary--text font-900">---</span>
                </div>
            </template>

            <!-- actions -->
            <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center justify-center">
                    <v-btn
                            :width="userType == 'doctor' ? '50%' : '100%'"
                            v-if="item.book_time && item.interview_link && item.status_id == 3"
                            color="register-primary"
                            dark
                            outlined
                            class="hover-primary-btn mx-1"
                            elevation="0"
                            @click="goTo(item)"
                    >
                        <span class="font-16 register-primary--text">{{ $t('dashboard.goToInterview') }}</span>
                    </v-btn>
                    <v-btn
                            width="50%"
                            v-if="
              item.status_id == 3 && item.interview_link && userType == 'doctor'
            "
                            color="register-primary"
                            dark
                            outlined
                            class="hover-primary-btn mx-1"
                            elevation="0"
                            @click="rescheduleHandler(item)"
                    >
                        <span class="font-16 register-primary--text">{{ $t('dashboard.reschedule') }} </span>
                    </v-btn>
                </div>
                <v-btn
                        v-if="
            item.status_id == 3 &&
            !item.interview_link &&
            userType == 'doctor' &&
            item.status_id != 5
          "
                        color="register-primary"
                        dark
                        outlined
                        block
                        class="hover-primary-btn mx-1"
                        elevation="0"
                        @click="setTimeHandler(item)"
                >
          <span class="font-16 register-primary--text"
          >{{ $t('dashboard.confirmEditMeeting') }}
          </span>
                </v-btn>
                <div
                        class="text-center w-100"
                        v-if="!item.book_time && item.status_id != 3 && !item.interview_link"
                >
          <span class="font-20 font-600 register-primary--text font-900">
            ---
          </span>
                </div>
            </template>
        </v-data-table>

        <!-- mobile view -->
        <mobile-view @goTo="goTo" v-if="isMobile" :items="items"></mobile-view>

        <!-- date dialog -->
        <v-dialog
                v-model="dialog"
                max-width="650px"
                :content-class="
        isMobile ? 'bluebgdash radius-15' : ' pa-4 bluebgdash radius-15'
      "
        >
            <date-picker
                    @success="successHandler"
                    @reschedule="rescheduleHandler"
                    @close="dialog = false"
                    :key="dialog"
                    :item="selectedItem"
            ></date-picker>
        </v-dialog>

        <!-- reschduual dialog -->
        <v-dialog
                v-model="reschedualDialog"
                max-width="450px"
                :content-class="
        isMobile ? 'bluebgdash radius-15' : ' pa-4 bluebgdash radius-15'
      "
        >
            <reschedual-time
                    @close="reschedualDialog = false"
                    :key="reschedualDialog"
                    :item="selectedItem"
                    @updateData="getData"
            ></reschedual-time>
        </v-dialog>

        <!-- success dialog -->
        <v-dialog
                v-model="successDialog"
                max-width="450px"
                :content-class="
        isMobile ? 'bluebgdash radius-15' : ' pa-4 bluebgdash radius-15'
      "
        >ٍ
            <success-book
                    @done="successDialog = false"
                    :key="successDialog"
            ></success-book>
        </v-dialog>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import DatePicker from "../../components/dialogs/DatePicker.vue";
import ReschedualTime from "../../components/dialogs/ReschedualTime.vue";
import SuccessBook from "../../components/dialogs/SuccessBook.vue";
import HeaderTable from "../../components/HeaderTable.vue";
import HeaderTableMobile from "../../components/HeaderTableMobile.vue";
import MobileView from "./MobileView.vue";
import {getMessaging, getToken} from "firebase/messaging";
// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging();
getToken(messaging, {vapidKey: 'BGOrwszZtk12MOpNdk8rqKgE1_pYMHScU9mXST3slSNbjoUigSe-MAbI8RGU3if5cKYpd7G7HJxV7cQo1sxyimM'}).then((currentToken) => {
    if (currentToken) {
        console.log(' registration token available. ', currentToken);
        // ...
    } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
    }
}).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
});
export default {
    components: {
        HeaderTable,
        DatePicker,
        HeaderTableMobile,
        MobileView,
        ReschedualTime,
        SuccessBook,
    },
    data: (vm) => ({
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        headers: [
            {
                text:
                    localStorage.getItem("role") == "doctor"
                        ? vm.$t("dashboard.hospitalName")
                        : vm.$t("dashboard.doctorName"),
                sortable: false,
                value: "hospital_name",
                width: "16%",
            },
            {
                text: vm.$t("dashboard.status"),
                value: "status",
                sortable: false,
                width: "17%",
            },
            {
                text: vm.$t("dashboard.interviewTime"),
                value: "interview_at",
                align: "center",
                sortable: false,
                width: "25%",
            },
            {
                text: vm.$t("dashboard.actions"),
                value: "actions",
                align: "center",
                sortable: false,
                width: "25%",
            },
        ],
        items: [],

        dialog: false,
        reschedualDialog: false,
        selectedItem: {},
        attrs: {
            class: "mb-6",
            boilerplate: true,
            elevation: 1,
        },
        loadingData: false,
        successDialog: false,

        itemsKeyUpdate: false,
    }),
    computed: {
        ...mapGetters(["userType"]),
    },
    methods: {
        successHandler() {
            this.dialog = false;
            this.successDialog = true;
            this.getData();
        },
        goTo(item) {
            window.open(item.interview_link, "_blank");
        },
        rescheduleHandler(item) {
            this.dialog = false;
            this.reschedualDialog = true;
            this.selectedItem = item;
        },
        setTimeHandler(item) {
            this.dialog = true;
            this.selectedItem = item;
        },
        getStatusColorText(status) {
            if (status == "Rejected") {
                return "error--text";
            }
            if (status == "pending") {
                return "yellowtextdash--text";
            } else {
                return "bluetextdash--text";
            }
        },
        getStatusColorBg(status) {
            if (status == "Rejected") {
                return "errorbg";
            }
            if (status == "pending") {
                return "yellowbgdash";
            } else {
                return "bluebgdashlight";
            }
        },
        async getData() {
            this.loadingData = true;
            this.reschedualDialog = false;
            let url;
            if (localStorage.getItem("role") == "doctor") {
                url = "interviews";
            } else {
                url = "hospital/interviews";
            }
            let {data} = await this.$axios.get(url);
            if (data.success) {
                this.items = data.data.interviews;
            }
            this.loadingData = false;
        },
    },
    async created() {
        await this.getData();
    },
};
</script>


<style lang="scss">
.interviews {
  border-radius: 15px;
  background: white;
  padding: 8px;

  thead {
    background: $bluebgdash;
    padding-left: 10px;

    span {
      font-size: 14px;
      font-weight: 600;
      color: #202a4f;
    }
  }

  tbody tr:nth-child(even) {
    background-color: $bluebgdash;
  }

  thead,
  tbody {
    tr {
      th,
      td {
        &:first-child {
          padding-left: 30px !important;
        }
      }
    }
  }

  tbody {
    tr {
      height: 70px;
    }

    tr:hover {
      background-color: transparent !important;
    }
  }

  @media (max-width: 960px) {
    padding: 0px;
  }
}

td,
th {
  border-bottom: none !important;
}
</style>
