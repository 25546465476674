<template>
  <div>
    <span class="d-block font-20 darkbord--text font-700"
      >Why you want to reschedule interview?</span
    >
    <v-form v-model="valid" ref="form" @submit.prevent="rescheduleHandler">
      <base-textarea
        class="mt-4 mb-3"
        placeholder="Reason...."
        title="Reasoen"
        v-model="message"
      ></base-textarea>
      <dialog-btns
        closeTitle="Cancel"
        btnTitle="Continue"
        :loading="loading"
        @close="$emit('close')"
      ></dialog-btns>
    </v-form>
  </div>
</template>

<script>
import BaseTextarea from "../BaseTextarea.vue";
import DialogBtns from "./DialogBtns.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: { DialogBtns, BaseTextarea },
  data: () => ({
    message: "",
    valid: false,
    loading: false
  }),
  methods: {
    async rescheduleHandler() {
      let valid = this.$refs.form.validate();
      if (!valid) {
        this.$store.dispatch("showSnack", {
          text: this.$t("rules.checkAllFields"),
          color: "error",
        });        
        return;
      }
      this.loading = true
      let formData = new FormData();
      formData.append("message", this.message);
      formData.append("order_id", this.item.order_id);
      let res = await this.$axios.post("reschedule/meeting", formData, {
        headers: {
          Accept: "application/json",
        },
      });

      if (res.data && res.data.success) {
        this.$store.dispatch("showSnack", {
          text: "Added Successfully",
          color: "success",
        });
        this.$emit('updateData')
      } else {
        this.$store.dispatch("showSnack", {
          text: res.errors.message[0],
          color: "error",
        });
      }
      this.loading = false
    },
  },
};
</script>

<style></style>
