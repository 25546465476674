<template>
  <div class="d-flex align-center" :class="topBtns ? '' : ' mt-8 w-100'">
    <v-btn
    v-if="closeTitle"
      :width="topBtns ? '75px' : '28%'"
      outlined
      class="mx-3"
      color="#333333"
      elevation="0"
      height="40px"
      @click="$emit('close')"
      ><span class="font-700 font-16"> {{ closeTitle }}</span></v-btn
    >
    <base-register-btn
    class="w-100"
      type="submit"
      :title="btnTitle"
      :loading="loading"
    ></base-register-btn>
  </div>
</template>

<script>
export default {
  props: {
    btnTitle: {
      type: String,
      default: "",
    },
    closeTitle: {
      type: String,
      default: "",
    },
    topBtns: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
