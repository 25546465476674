<template>
  <div>
    <span
      class="font-24 font-700 darktext--text d-block mb-3"
      :class="isMobile ? 'pt-3 px-3' : ''"
      >Select the date you want</span
    >

    <!-- date picker -->
    <div class="bluebgdash" :class="isMobile ? '' : 'pa-4'">
      <v-date-picker
        no-title
        class="date-picker"
        full-width
        :allowed-dates="allowedDates"
        color="black"
        @change="dateClicked"
        v-model="date"
      ></v-date-picker>
    </div>
    <!-- select time -->
    <div class="mt-3" :class="isMobile ? '' : ' d-flex align-center'">
      <span class="font-24 font-700 darktext--text px-3"
        >Select the time you want in</span
      >
      <div
        v-if="selectedDate"
        class="d-flex align-center lightregister-primary px-3 py-2 radius-5 mx-3 mt-1"
      >
        <span class="d-inline-block mr-2 font-600 darkbord--text">{{
          selectedDate
        }}</span>
        <!-- <span class="d-inline-block mr-2 font-600 white--text">From </span>
        <span class="d-inline-block font-700 white--text">10 AM</span>
        <span class="d-inline-block line-between mx-4"></span>
        <span class="d-inline-block mr-2 font-600 white--text">To </span>
        <span class="d-inline-block font-700 white--text">8 PM</span> -->
      </div>
    </div>

    <!-- you time -->
    <span class="d-block font-20 font-700 darktext--text px-3 mt-2"
      >Your interview time</span
    >
    <div class="bluebgdash mt-4">
      <v-row class="px-5" :key="timeKeyUpdated">
        <v-col
          cols="6"
          md="4"
          v-for="(time, i) in times"
          :key="i"
          class="px-1 py-1"
        >
          <div
            class="white radius-5 py-3 text-center transition-03 cursor-pointer"
            :class="time.selected ? 'register-primary' : ''"
            @click="timeSelected(time, i)"
          >
            <span
              class="font-14 font-700 transition-03"
              :class="time.selected ? ' white--text' : ' darkprimary--text'"
            >
              {{ time.startTime != null ? time.startTime : "00" }}</span
            >
            -
            <span
              class="font-14 font-700 transition-03"
              :class="time.selected ? ' white--text' : ' darkprimary--text'"
            >
              {{ time.endTime != null ? time.endTime : "00" }}</span
            >
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="text-right px-3 pb-3 mt-5">
      <v-row align="center">
        <v-col cols="12" md="5">
          <v-btn
            color="#C2FFF4"
            dark
            elevation="0"
            width="250px"
            height="50px"
            @click="$emit('reschedule', item)"
          >
            <span class="font-16 register-primary--text font-500"
              >{{ $t('dashboard.reschedule') }}</span
            >
          </v-btn>
        </v-col>
        <v-col cols="12" md="7">
          <base-register-btn
            title="Choose"
            :loading="loading"
            @click="submitHandler"
          ></base-register-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    picker: null,
    data: [],
    times: [],
    dates: [],
    selectedDate: "",
    timeKeyUpdated: false,
    obj: {},
    date: "",
    loading: false,
  }),
  methods: {
    dateClicked(event) {
      this.selectedDate = event;
      this.data.forEach((date) => {
        if (event == date.date) {
          this.times = date.times;
          this.obj.date = date.date;
          this.times.forEach((time) => {
            time.id = Math.random();
            time.selected = false;
          });
        }
      });
    },
    allowedDates(val) {
      return this.dates.includes(val);
    },
    timeSelected(time) {
      this.times.forEach((t) => {
        if (t.id == time.id) {
          t.selected = true;
          this.obj.chosen_time =
            (time.startTime != null ? time.startTime : "00")
        } else {
          t.selected = false;
        }
      });
      this.timeKeyUpdated = !this.timeKeyUpdated;
    },
    async getTimes() {
      let formData = new FormData();
      formData.append("order_id", this.item.order_id);

      let { data } = await this.$axios.post("doctor/times", formData);
      if (data) {
        this.dates = [];
        this.data = data.data.times;
        let dataDate = data.data.times.map((date) => date.date);
        dataDate.forEach((date) => {
          this.dates.push(date);
        });
        this.date = this.dates[0]
        this.dateClicked(this.date)
      }
    },
    async submitHandler() {
      let formData = new FormData();
      if (!this.obj.chosen_time) {
        this.$store.dispatch("showSnack", {
          text: "Please select time",
          color: "error",
        });
        return;
      }
      if (!this.obj.date) {
        this.$store.dispatch("showSnack", {
          text: "Please select date",
          color: "error",
        });
        return;
      }
      this.loading = true;
      formData.append("chosen_time", this.obj.chosen_time);
      formData.append("date", this.obj.date);
      formData.append("order_id", this.item.order_id);
      formData.append("hospital_id", this.item.hospital.id);

      let { data } = await this.$axios.post(
        "save/meeting/with/clinic",
        formData
      );
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Added Successfully",
          color: "success",
        });
        this.$emit("success");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
      this.loading = false;
    },
  },
  async created() {
    await this.getTimes();
  },
};
</script>

<style lang="scss">
.line-between {
  width: 1px;
  height: 35px;
  background: white;
}
.v-date-picker-header,
.v-date-picker-body {
  background: $bluebgdash;
}
.v-date-picker-table {
  background: $bluebgdash;
  height: auto;
  tbody {
    td {
      height: 40px;
      width: 30px;
      padding: 1px;
      button {
        background: white;
        border-radius: 5px !important;
        height: 100% !important;
        width: 100% !important;
        div {
          font-size: 15px;
        }
      }
    }
  }
  .v-btn {
    border: 1px solid rgb(233, 233, 233) !important;
    &--disabled {
      border: none !important;
      background: none;
    }
    &--active {
      color: white;
      background: $register-primary !important;
    }
  }
}
</style>
