var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bluebgdash px-5 mt-5 pt-1"},_vm._l((_vm.items),function(item,i){return _c('v-row',{key:i,staticClass:"white radius-5 mt-3 mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"mr-3 circle",attrs:{"width":"32px","height":"32px","src":require('@/assets/images/register-bg.png')}}),(item.hospital)?_c('span',{staticClass:"font-16 greytext--text"},[_vm._v(_vm._s(item.hospital.name))]):_c('span',{staticClass:"font-16 greytext--text"},[_vm._v(_vm._s(item.doctor_name))])]),_c('div',{staticClass:"px-4 py-2 radius-10 d-inline-block pr-10",class:_vm.getStatusColorBg(item.status)},[_c('span',{staticClass:"font-16 font-700",class:_vm.getStatusColorText(item.status)},[_vm._v(_vm._s(item.status))])])])]),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[(item.book_time && _vm.userType == 'doctor')?_c('span',{staticClass:"font-16 greytext--text"},[_vm._v(_vm._s(item.interview_at))]):_vm._e(),(item.interview_time && _vm.userType != 'doctor' && !item.book_time)?_c('span',{staticClass:"font-16 greytext--text"},[_vm._v(_vm._s(item.interview_at))]):_vm._e(),(!item.book_time && !item.interview_time)?_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"font-16 register-primary--text font-900"},[_vm._v("---")])]):_vm._e()]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center pr-4"},[(item.book_time && item.interview_link && item.status_id < 4)?_c('v-btn',{staticClass:"hover-primary-btn mr-3",attrs:{"width":_vm.userType == 'doctor' ? '50%' : '100%',"color":"register-primary","dark":"","outlined":"","elevation":"0"},on:{"click":function($event){return _vm.goTo(item)}}},[_c('span',{staticClass:"font-16 register-primary--text"},[_vm._v(_vm._s(_vm.$t('dashboard.goToInterview')))])]):_vm._e(),(
            item.status_id == 3 && item.interview_link && _vm.userType == 'doctor'
          )?_c('v-btn',{staticClass:"hover-primary-btn",attrs:{"width":"50%","color":"register-primary","dark":"","outlined":"","elevation":"0"},on:{"click":function($event){return _vm.rescheduleHandler(item)}}},[_c('span',{staticClass:"font-16 register-primary--text"},[_vm._v(_vm._s(_vm.$t('dashboard.reschedule'))+" ")])]):_vm._e()],1),(
          item.status_id == 3 &&
          !item.interview_link &&
          _vm.userType == 'doctor' &&
          item.status_id != 5
        )?_c('v-btn',{staticClass:"hover-primary-btn",attrs:{"color":"register-primary","dark":"","outlined":"","block":"","elevation":"0"},on:{"click":function($event){return _vm.setTimeHandler(item)}}},[_c('span',{staticClass:"font-16 register-primary--text"},[_vm._v(_vm._s(_vm.$t('dashboard.confirmEditMeeting'))+" ")])]):_vm._e(),(!item.book_time && item.status_id != 3 && !item.interview_link)?_c('div',{staticClass:"text-center w-100"},[_c('span',{staticClass:"font-20 font-600 register-primary--text font-900"},[_vm._v(" --- ")])]):_vm._e()],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }