<template>
  <div class="white header-table-mobile px-3 dark-shadow py-4 mb-4">
    <v-row justify="space-between" class="pr-4">
      <v-col cols="4">
        <!-- head title -->
        <span class="darkbord--text font-16 font-600">{{ title }}</span>
      </v-col>

      <v-col cols="3"  class="px-3">
        <span class="register-primary--text font-500" v-if="loadingSearch"
          >{{ $t("dashboard.loading") }}...</span
        >
      </v-col>
      <!-- search -->
      <v-col cols="12" class="px-1 text-right" v-if="isSort">
        <v-text-field
          :hide-details="true"
          dense
          class="table-input px-3"
          color="register-primary"
          :placeholder="$t('forms.search')"
          append-icon="mdi-magnify"
          background-color="white"
          filled
          @keyup="searchHandler"
          v-model="searchInput"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    isSort: {
      type: Boolean,
      default: false,
    },
    createNewTitle: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },    
  },
  data: () => ({
    displays: [
      { title: "5 rows per page", value: 5 },
      { title: "10 rows per page", value: 10 },
      { title: "20 rows per page", value: 20 },
      { title: "30 rows per page", value: 30 },
    ],
    searchInput: "",
    timer: undefined,
    loadingSearch: false
  }),
  methods: {
    async searchHandler() {
      this.loadingSearch = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        let { data } = await this.$axios.post(this.url, {
          value: this.searchInput,
        });
        if (data.success) {
          this.$emit("updateDate", data.data);
        }
        this.loadingSearch = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss">
.header-table-mobile {
  padding: 16px 24px;
  box-shadow: 0px -3px 34px 0px #00000040;

  @media (max-width: 960px) {
    padding: 0px;
  }
}
.custom-pagination {
  .v-pagination {
    li {
      display: none;
      margin: 0px 15px;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        background: #cdfff6;
      }
      &:last-child,
      &:first-child {
        display: block;
      }

      &:first-child {
        padding-right: 10px;

        button {
          width: max-content;
          box-shadow: none;
          background: transparent;
          &::after {
            content: "Back";
            color: #33588f;
            font-size: 14px;
          }
          i {
            color: #33588f;
            font-size: 18px;
            margin-bottom: 2px;
          }
        }
      }
      &:last-child {
        padding-left: 10px;

        button {
          width: max-content;
          box-shadow: none;
          background: transparent;
          &::before {
            content: "Next";
            color: #33588f;
            font-size: 14px;
          }
          i {
            color: #33588f;
            font-size: 18px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}
.table-input {
  padding-top: 0px;
  margin-top: 0px;

  .v-input__slot {
    &::before,
    &::after {
      display: none;
    }
  }
  .v-select__selections {
    padding-left: 10px;
  }
}
</style>
