<template>
  <div class="bluebgdash px-5 mt-5 pt-1">
    <v-row class="white radius-5 mt-3 mb-2" v-for="(item, i) in items" :key="i">
      <v-col cols="12">
        <div class="d-flex align-center justify-space-between">
          <!-- image & name -->

          <div class="d-flex align-center">
            <img
              width="32px"
              height="32px"
              class="mr-3 circle"
              :src="require('@/assets/images/register-bg.png')"
            />
            <span class="font-16 greytext--text" v-if="item.hospital">{{
              item.hospital.name
            }}</span>
            <span class="font-16 greytext--text" v-else>{{
              item.doctor_name
            }}</span>
          </div>

          <!-- status -->
          <div
            class="px-4 py-2 radius-10 d-inline-block pr-10"
            :class="getStatusColorBg(item.status)"
          >
            <span
              class="font-16 font-700"
              :class="getStatusColorText(item.status)"
              >{{ item.status }}</span
            >
          </div>
        </div>
      </v-col>

      <!-- time -->
      <v-col cols="12" class="py-1">
        <span
          class="font-16 greytext--text"
          v-if="item.book_time && userType == 'doctor'"
          >{{ item.interview_at }}</span
        >
        <span
          class="font-16 greytext--text"
          v-if="item.interview_time && userType != 'doctor' && !item.book_time"
          >{{ item.interview_at }}</span
        >
        <div class="text-center" v-if="!item.book_time && !item.interview_time">
          <span class="font-16 register-primary--text font-900">---</span>
        </div>
      </v-col>
      <v-col cols="12">
        <div class="d-flex align-center pr-4">
          <v-btn
            :width="userType == 'doctor' ? '50%' : '100%'"
            v-if="item.book_time && item.interview_link && item.status_id < 4"
            color="register-primary"
            dark
            outlined
            class="hover-primary-btn mr-3"
            elevation="0"
            @click="goTo(item)"
          >
            <span class="font-16 register-primary--text">{{$t('dashboard.goToInterview')}}</span>
          </v-btn>
          <v-btn
            width="50%"
            v-if="
              item.status_id == 3 && item.interview_link && userType == 'doctor'
            "
            color="register-primary"
            dark
            outlined
            class="hover-primary-btn"
            elevation="0"
            @click="rescheduleHandler(item)"
          >
            <span class="font-16 register-primary--text">{{$t('dashboard.reschedule')}} </span>
          </v-btn>
        </div>
        <v-btn
          v-if="
            item.status_id == 3 &&
            !item.interview_link &&
            userType == 'doctor' &&
            item.status_id != 5
          "
          color="register-primary"
          dark
          outlined
          block
          class="hover-primary-btn"
          elevation="0"
          @click="setTimeHandler(item)"
        >
          <span class="font-16 register-primary--text"
            >{{$t('dashboard.confirmEditMeeting')}}
          </span>
        </v-btn>
        <div
          class="text-center w-100"
          v-if="!item.book_time && item.status_id != 3 && !item.interview_link"
        >
          <span class="font-20 font-600 register-primary--text font-900">
            ---
          </span>
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters(["userType"]),
  },
  methods: {
    successHandler() {
      this.dialog = false;
      this.successDialog = true;
      this.getData();
    },
    goTo(item) {
      window.open(item.interview_link, "_blank");
    },
    rescheduleHandler(item) {
      this.dialog = false;
      this.reschedualDialog = true;
      this.selectedItem = item;
    },
    setTimeHandler(item) {
      this.dialog = true;
      this.selectedItem = item;
    },
    getStatusColorText(status) {
      if (status == "Rejected") {
        return "error--text";
      }
      if (status == "pending") {
        return "yellowtextdash--text";
      } else {
        return "bluetextdash--text";
      }
    },
    getStatusColorBg(status) {
      if (status == "Rejected") {
        return "errorbg";
      }
      if (status == "pending") {
        return "yellowbgdash";
      } else {
        return "bluebgdashlight";
      }
    },
    async getData() {
      this.loadingData = true;
      this.reschedualDialog = false;
      let url;
      if (localStorage.getItem("role") == "doctor") {
        url = "interviews";
      } else {
        url = "hospital/interviews";
      }
      let { data } = await this.$axios.get(url);
      if (data.success) {
        this.items = data.data.interviews;
      }
      this.loadingData = false;
    },
  },
};
</script>

<style lang="scss"></style>
